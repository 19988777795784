import React from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import Page from 'components/common/Page'

const PaymentResult = () => {
  const navigate = useNavigate()
  return <Page>
    <Container>
      <img className='mobile-slogan' src='images/introduceSlogan.png' alt='introduceSlogan.png' />
      <br />
      <h2>결제가 완료됐어요!</h2>
      <h4>티켓을 구매해주셔서 감사해요!</h4>
      <button onClick={() => navigate('/purchase-history')}>구매 내역 확인하기</button>
    </Container>
  </Page>
};

export default PaymentResult;

const Container = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10rem 0;
`;
