import React from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"

import Footer from "components/Footer"
import Nav from "components/Nav"

import Login from "pages/Login"
import { EmailLogin } from "pages/Login/signInByEmail"
import NotFound from "pages/NotFound"
import { PrivacyPolicy } from "pages/PrivacyPolicy"
import { TermsOfService } from "pages/TermsOfService"
import TicketPage from "pages/Ticket"
import PaymentList from "pages/Ticket/paymentList"
import styled from "styled-components"

const Router = () => {
  return (
    <BrowserRouter>
      <Wrapper>
        <Nav />
        <Routes>
          <Route path="/" element={<Navigate replace to={"/ticket"} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login/email" element={<EmailLogin />} />
          <Route path="/ticket" element={<TicketPage />} />
          <Route path="/purchase-history" element={<PaymentList />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate replace to={"/404"} />} />
        </Routes>
        <Footer />
      </Wrapper>
    </BrowserRouter>
  );
};

const Wrapper = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
`

export default Router;
