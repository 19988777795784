import { mdiLoginVariant } from "@mdi/js"
import Icon from "@mdi/react"
import { wrap } from "@suspensive/react"
import React from "react"
import { Navigate, useNavigate } from "react-router"
import styled from "styled-components"

import { useQueryClient } from "@tanstack/react-query"
import { LoadingIcon } from "components/common/Element/loading"
import Page from "components/common/Page"
import { EmailSignInRequest } from "models/api/user"
import { getFormValue } from "utils/form"
import { QUERY_KEYS, useEmailSignInMutation, useUserEmail } from "utils/hooks/useAPIs"
import useTranslation from "utils/hooks/useTranslation"

const REDIRECT_TO = '/ticket'

const EmailLoginForm: React.FC = () => {
  const signInFormRef = React.useRef<HTMLFormElement>(null)
  const t = useTranslation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const idpwSignInMutation = useEmailSignInMutation()
  const [isError, setIsError] = React.useState(false)

  const signIn = () => {
    if (!signInFormRef.current) return
    if (!signInFormRef.current.checkValidity()) {
      signInFormRef.current.reportValidity()
      return
    }
    setIsError(false)
    idpwSignInMutation.mutate(
      getFormValue<EmailSignInRequest>({ form: signInFormRef.current }),
      {
        onError: () => setIsError(true),
        onSuccess: async () => {
          queryClient.removeQueries({ queryKey: QUERY_KEYS.USER_EMAIL })
          await queryClient.invalidateQueries()
          navigate(REDIRECT_TO)
          setIsError(false)
        },
      }
    )
  }

  return (
    <Container>
      <h1>{t("기업 계정 로그인")}</h1>
      {isError && <ErrorBox>
        {t("로그인에 실패했습니다,")}
        <br />
        {t("다시 시도하시거나 다른 방법으로 로그인해주세요.")}
        <br />
        {t("문제가 지속되면 ")}<a href="mailto:pyconkr@pycon.kr">pyconkr@pycon.kr</a>{t("으로 연락 부탁드립니다.")}
      </ErrorBox>
      }
      <form ref={signInFormRef} style={{ width: '100%' }}>
        <label>
          {t('이메일')}&nbsp;
          <input
            type="email"
            name="email"
            placeholder={t('이메일') as string}
            disabled={idpwSignInMutation.isPending}
          />
        </label>
        <label>
          {t('비밀번호')}&nbsp;
          <input
            type="password"
            name="password"
            placeholder={t('비밀번호') as string}
            disabled={idpwSignInMutation.isPending}
          />
        </label>
      </form>
      <SocialLoginButton
        onClick={signIn}
        disabled={idpwSignInMutation.isPending}
        style={{ backgroundColor: 'var(--pico-primary-background)' }}
      >
        <Icon path={mdiLoginVariant} size={1} />
        <div>{t('로그인')}</div>
        <Icon path={mdiLoginVariant} size={1} style={{ color: 'var(--pico-primary-background)' }} />
      </SocialLoginButton>

    </Container>
  )
}

export const EmailLogin = () => {
  const t = useTranslation()
  const queryClient = useQueryClient()

  const SignInCheckerHelmet = wrap
    .ErrorBoundary({
      fallback: () => {
        queryClient.invalidateQueries({ queryKey: QUERY_KEYS.USER_EMAIL })
        return <EmailLoginForm />
      },
    })
    .Suspense({ fallback: <LoadingIcon /> })
    .on(() => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const userEmailQuery = useUserEmail()
      return (userEmailQuery.data) ? <Navigate to={REDIRECT_TO} /> : <EmailLoginForm />
    })

  return (
    <Page>
      <Container>
        <SignInCheckerHelmet />
      </Container>
    </Page>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`

const SocialLoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  margin: 10px 0;
  padding: 0 1rem;
  border: 1px solid #000;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
`

const ErrorBox = styled.div`
  background-color: rgba(255, 0, 0, 0.25);
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid #ffaaaa;

  margin-bottom: 1rem;
  color: #ffaaaa;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
`
