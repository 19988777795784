import React from 'react'

import useTranslation from 'utils/hooks/useTranslation'

export const PriceDisplay: React.FC<{ price: number, label?: string }> = ({ price, label }) => {
  const t = useTranslation()
  return <>
    {t(label ?? '')}
    {label ? ' : ' : ''}
    {price.toLocaleString()}
    {t('원')}
  </>
}
