import React from 'react'
import { Helmet } from 'react-helmet'
import useTranslation from 'utils/hooks/useTranslation'
import './style.scss'
import styled from 'styled-components'

const Page = ({ children, title }: React.HTMLAttributes<HTMLDivElement> & { title?: string }) => {
  const t = useTranslation();

  return (
    <div className='cm-page-container'>
      <div className='cm-page'>
        <Helmet title={!title ? `${t('파이콘 한국')} 2024` : `${t('파이콘 한국')} 2024 | ${title}`} />
        {children}
      </div>
    </div>
  );
};

export default Page;

export const PolicyPageContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
`;
