import { isEmpty } from 'remeda'

import { CartItemAppendRequest } from 'models/api/order'
import { Product } from 'models/product'

export function getFormValue<T>(_: {
  form: HTMLFormElement
  fieldToExcludeWhenFalse?: string[]
  fieldToNullWhenFalse?: string[]
}): T {
  const formData: {
    [k: string]: FormDataEntryValue | boolean | null
  } = Object.fromEntries(new FormData(_.form))
  Object.keys(formData)
    .filter((key) => (_.fieldToExcludeWhenFalse ?? []).includes(key) || (_.fieldToNullWhenFalse ?? []).includes(key))
    .filter((key) => isEmpty(formData[key] as string))
    .forEach((key) => {
      if ((_.fieldToExcludeWhenFalse ?? []).includes(key)) {
        delete formData[key]
      } else if ((_.fieldToNullWhenFalse ?? []).includes(key)) {
        formData[key] = null
      }
    })
  Array.from(_.form.children).forEach((child) => {
    const targetElement: Element | null = child
    if (targetElement && !(targetElement instanceof HTMLInputElement)) {
      const targetElements = targetElement.querySelectorAll('input')
      for (const target of targetElements)
        if (target instanceof HTMLInputElement && target.type === 'checkbox')
          formData[target.name] = target.checked ? true : false
    }
  })
  return formData as T
}

const UUID_REGEX = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i

export const getOrderProductInfoFromForm = (
  product: Product,
  optionFormRef: React.RefObject<HTMLFormElement | null>,
  donationInputRef: React.RefObject<HTMLInputElement | null>
) => {
  if (!optionFormRef.current) return
  if (!optionFormRef.current.checkValidity()) {
    optionFormRef.current.reportValidity()
    return
  }
  const formData = getFormValue<{ [k: string]: string }>({ form: optionFormRef.current })

  let donationPrice = 0
  if (product.donation_allowed && donationInputRef.current) {
    const donationEl = donationInputRef.current
    if (!donationEl.checkValidity()) {
      const errMsg = `후원금은 ${product.donation_min_price.toLocaleString()}원 이상 ${product.donation_max_price.toLocaleString()}원 이하로 입력해주세요`
      donationEl.setCustomValidity(errMsg)
      donationEl.reportValidity()

      const untriggerer = (e: Event) => {
        donationEl.setCustomValidity('')
        donationEl.reportValidity()
        donationEl.removeEventListener('change', untriggerer)
      }
      donationEl.addEventListener('change', untriggerer)
      return
    }

    donationPrice = parseInt(donationInputRef.current.value)
    if (isNaN(donationPrice)) {
      donationEl.setCustomValidity('숫자만 입력해주세요')
      donationEl.reportValidity()
      return
    }
  }

  const requestData: CartItemAppendRequest = {
    product: product.id,
    options: Object.entries(formData).map(
      ([key, value]) => {
        const isCustomResponse = !UUID_REGEX.test(value)
        return {
          product_option_group: key,
          product_option: isCustomResponse ? null : value,
          custom_response: isCustomResponse ? value : null,
        }
      }
    ),
    ...(donationPrice > 0 ? { donation_price: donationPrice } : {}),
  }
  return requestData
}
