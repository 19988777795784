import { wrap } from '@suspensive/react'
import React from 'react'
import { Navigate } from 'react-router'

import { ErrorFallbackRenderer } from 'components/ErrorFallbackRenderer'
import { LoadingIcon } from 'components/common/Element/loading'
import { useUserEmail } from 'utils/hooks/useAPIs'

type SignInHelmetProps = React.PropsWithChildren<{
  alertText: string
  confirm?: boolean
}>

/**
 * 로그인 정보를 확인하고, 없으면 로그인 페이지로 이동하는 컴포넌트를 생성하는 함수입니다.
 * @param reset useQueryErrorResetBoundary().reset 함수
 * @returns React.FC<{ children?: React.ReactNode, alertText: string }>
 */
export const SignInHelmetGenerator = (reset?: () => void) => {
  return wrap
  .ErrorBoundary({
    onReset: reset,
    fallback: (props) => <ErrorFallbackRenderer {...props}>
      로그인 정보를 확인하던 중 문제가 발생했어요,<br />
      잠시 후 다시 시도해주세요.
    </ErrorFallbackRenderer>,
  })
  .Suspense({ fallback: <LoadingIcon /> })
  .on((props: SignInHelmetProps) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const userEmailQuery = useUserEmail()

    if (!userEmailQuery.data) {
      if (props.confirm) {
        if (!window.confirm(props.alertText)) {
          return <Navigate to='/login' />
        }
      } else {
        alert(props.alertText)
        return <Navigate to='/login' />
      }
    }

    return props.children
  })
}
