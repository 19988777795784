import { mdiAccountCircleOutline, mdiGoogle } from "@mdi/js"
import Icon from "@mdi/react"
import { wrap } from "@suspensive/react"
import React from "react"
import { Navigate, useNavigate } from "react-router"
import styled from "styled-components"

import { useQueryClient } from "@tanstack/react-query"
import { defaultRequestErrorHandler } from "api_v2"
import { LoadingIcon } from "components/common/Element/loading"
import Page from "components/common/Page"
import { SocialSignInProvider } from "models/api/user"
import { useSearchParams } from "react-router-dom"
import { QUERY_KEYS, useSNSProviderMutation, useUserEmail } from "utils/hooks/useAPIs"
import useTranslation from "utils/hooks/useTranslation"

const REDIRECT_TO = '/ticket'
const PROVIDERS: {
  id: SocialSignInProvider
  name: string
  icon: string
  backgroundColor: string
  symbolColor: string
}[] = [
    {
      id: 'google',
      name: 'Google로 로그인',
      icon: mdiGoogle,
      backgroundColor: '#4285F4',
      symbolColor: '#fff',
    },
    // {
    //   id: 'github',
    //   name: 'Github으로 로그인',
    //   icon: mdiGithub,
    //   backgroundColor: '#000',
    //   symbolColor: '#fff',
    // },
    {
      id: 'naver',
      name: '네이버로 로그인',
      icon: mdiAccountCircleOutline,
      backgroundColor: '#03C75A',
      symbolColor: '#fff',
    },
    {
      id: 'kakao',
      name: '카카오로 로그인',
      icon: mdiAccountCircleOutline,
      backgroundColor: '#FEE500',
      symbolColor: '#000',
    },
  ]

const SNSLoginForm: React.FC = () => {
  const t = useTranslation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const snsProviderMutation = useSNSProviderMutation()
  const [searchParams, _] = useSearchParams()
  const isError = searchParams.get('error') === 'true'

  const currentUrl = window.location.href

  return (
    <Container>
      <h1>{t("로그인")}</h1>
      {isError && <ErrorBox>
        {t("로그인에 실패했습니다,")}
        <br />
        {t("다시 시도하시거나 다른 방법으로 로그인해주세요.")}
        <br />
        {t("문제가 지속되면 ")}<a href="mailto:pyconkr@pycon.kr">pyconkr@pycon.kr</a>{t("으로 연락 부탁드립니다.")}
      </ErrorBox>
      }
      {PROVIDERS.map((p) => {
        const onClick = () => snsProviderMutation.mutate(
          { provider: p.id, callback_url: currentUrl, process: 'login' },
          {
            onSuccess: async () => {
              queryClient.removeQueries({ queryKey: QUERY_KEYS.USER_EMAIL })
              await queryClient.invalidateQueries()
            },
            onError: defaultRequestErrorHandler,
          }
        )
        return <SocialLoginButton
          key={p.id}
          onClick={onClick}
          disabled={snsProviderMutation.isPending}
          style={{ backgroundColor: p.backgroundColor, color: p.symbolColor }}
        >
          <Icon path={p.icon} size={1} />
          <div>{t(p.name)}</div>
          <Icon path={p.icon} size={1} style={{ color: p.backgroundColor }} />
        </SocialLoginButton>
      })}
      <hr style={{ width: '100%' }} />
      {/* <SocialLoginButton
        onClick={() => navigate('/login/email')}
        disabled={snsProviderMutation.isPending}
        style={{ backgroundColor: 'var(--pico-primary-background)' }}
      >
        <Icon path={mdiLoginVariant} size={1} />
        <div>{t('기업 계정 로그인')}</div>
        <Icon path={mdiLoginVariant} size={1} style={{ color: 'var(--pico-primary-background)' }} />
      </SocialLoginButton> */}
    </Container>
  )
}

const Login = () => {
  const t = useTranslation()
  const queryClient = useQueryClient()

  const SignInCheckerHelmet = wrap
    .ErrorBoundary({
      fallback: () => {
        queryClient.invalidateQueries({ queryKey: QUERY_KEYS.USER_EMAIL })
        return <SNSLoginForm />
      },
    })
    .Suspense({ fallback: <LoadingIcon /> })
    .on(() => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const userEmailQuery = useUserEmail()
      return (userEmailQuery.data) ? <Navigate to={REDIRECT_TO} /> : <SNSLoginForm />
    })

  return (
    <Page>
      <Container>
        <SignInCheckerHelmet />
      </Container>
    </Page>
  )
}

export default Login

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`

const SocialLoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  margin: 10px 0;
  padding: 0 1rem;
  border: 1px solid #000;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
`

const ErrorBox = styled.div`
  background-color: rgba(255, 0, 0, 0.25);
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid #ffaaaa;

  margin-bottom: 1rem;
  color: #ffaaaa;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
`
