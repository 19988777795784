import React from "react";

const EnglishTranslation: { [key: string]: string | React.ReactNode } = {
  // NavBar
  "PyCon 한국 홈페이지": "PyCon Korea 2024",

  web: "Web",
  social: "Social",
  unknown: "Unknown",
  "파이콘 한국": "PyCon Korea",
  준비위원회: "Organizing Team",
  "언어 변경": "Change language",
  언어: "Language",
  "파이콘 한국 2024": "PyCon Korea 2024",
  "파이콘 한국 2024는 파이콘 한국 준비위원회가 만들고 있습니다.":
    "PyCon Korea 2024 is being made by PyCon Korea Organizing Team.",
  "파이썬 웹 프레임워크 ": "Powered by ",
  "로 만들었습니다.": "",
  "서비스 이용 약관": "Terms of Service",
  "개인 정보 처리 방침": "Privacy Protection Policy",
  "페이지를 찾을 수 없습니다.": "Page not found.",
  로그인: "Log in",
  로그아웃: "Log out",
  "기업 계정 로그인": "Business account Sign in",
  티켓: "Ticket",
  "구매 내역": "Purchase history",
  구매하기: "Purchase",
  "티켓 구매하기": "Buy ticket",
  상품명: "Product name",
  가격: "Price",
  구매: "Buy",
  "티켓을 구매하려면 먼저 로그인이 필요합니다.\n로그인 페이지로 이동하시겠습니까?":
    "You need to log in to buy a ticket.\nWould you like to go to the login page?",
  후원: "Donation",
  "후원을 통해 PyCon 한국 준비 위원회와 함께해주셔서 정말 감사합니다!":
    "Thank you for supporting PyCon Korea Organizing Team!",
  "혹시 추가로 후원하고 싶은 금액이 있으시면, 아래에 입력해주시면 추가로 후원해주실 수 있습니다!":
    "If you would like to donate more, you can donate more by entering the amount below!",
  "금액은 ": "The possible amount for additional donation is ",
  "에서 ": " to ",
  " 내로 추가 후원하실 수 있습니다.": ".",
  "후원 금액": "Donation amount",
  "상품 정보": "Product information",
  옵션: "Options",
  "까지 환불이 가능합니다.": " is refundable until.",
  "상품을 구매하시면 관련": "By purchasing the product, you agree to the",
  및: " and ",
  "에 동의한 것으로 간주합니다.": ".",
  원: " KRW",
  "남은 금액": "Remaining amount",
  "컨퍼런스 티켓": "Conference ticket",
  "튜토리얼 티켓": "Tutorial ticket",
  "스프린트 티켓": "Sprint ticket",
  취소: "Cancel",
  "결제 내역": "Order list",
  "상품 주문 후 노출에 최대 3분 정도 소요될 수 있어요, 만약 3분 후에도 노출되지 않을 경우 파이콘 한국 준비위원회에 문의해주세요.":
    "It may take up to 3 minutes for the product order to be displayed. If it is not displayed after 3 minutes, please contact the PyCon Korea Organizing Team.",
  "입장 QR 코드": "Entrance QR code",
  환불: "Refund",
  환불하기: "Refund",
  "환불 성공": "Refund success",
  "환불 실패": "Refund failed",
  "결제 상태": "Order status",
  주문명: "Order name",
  "결제 번호": "Order ID",
  "결제 일시": "Order date",
  금액: "Price",
  "상세 정보": "Order details",
  "영수증 관련 문의는 ": "For inquiries about receipts, please contact ",
  "로 문의해주세요.": ".",
  "옵션 수정": "Apply changes",
  "수정 가능일이 지나지 않은 일부 옵션만 수정하실 수 있습니다.":
    "You can only modify some options that are not past the modification date.",
  변경일: "Updated at",
  "Google로 로그인": "Sign in with Google",
  "Github로 로그인": "Sign in with Github",
  "네이버로 로그인": "Sign in with Naver",
  "카카오로 로그인": "Sign in with Kakao",
  "로그인에 실패했습니다,": "Login failed,",
  "다시 시도하시거나 다른 방법으로 로그인해주세요.":
    "Please try again or log in with another method.",
  "문제가 지속되면 ": "If the problem persists, please contact ",
  "으로 연락 부탁드립니다.": ".",
  "컨퍼런스 티켓 관련 안내": "Announcement",
  '기업/단체 명의의 계좌 이체 및 세금계산서 발행이 필요한 경우 "기업/단체 티켓"으로 구매하셔야 합니다.':
    'If you need to transfer money from a company/organization account or issue a tax invoice, you must purchase a "Corporate/Organization Ticket."',
  "가격은 장당 16만 원입니다.": "The price is 160,000 won per ticket.",
  "기업/단체에서의 파이썬 커뮤니티에 대한 관심과 후원의 의미가 있습니다":
    "It is meaningful to show interest and support for the Python community in companies/organizations.",
  '"기업/단체 티켓"은 메일': 'To buy "Corporate/Organization Ticket", please contact to mail',
  "로 문의주시기 바랍니다.": ".",
  "뭉치면 할인!": "Group discount",
  "5인 이상 단체로 구매하시는 경우 20% 할인이 됩니다.":
    "If you purchase in a group of 5 or more, you will receive a 20% discount.",
  "계좌 이체로만 구매가 가능합니다. (현금영수증 가능)":
    "Purchases can only be made by bank transfer. (Cash receipts available)",
  "할인 받은 티켓은 재정 지원 대상이 아닙니다.":
    "Discounted tickets are not eligible for financial aid.",
  '"뭉치면 할인!" 티켓은 메일': 'To buy "Group discount" ticket, please contact to mail',
  품절: "Sold out",
  // 상품 정보, 추후 백엔드에서 다국어 지원을 해야 합니다.
  티셔츠: "T-shirt",
  성함: "Name",
  소속: "Organization",
  이메일: "Email",
  비밀번호: "Password",
  "연락처 번호 (-를 포함해주세요!)": (
    <>
      Phone number
      <div className="hideOnHistory">
        For a Korean phone number, put a hyphen between the phone numbers,
        <br />
        <code>ex) 010-0000-0000</code>
        <br />
        and for a non-Korean phone number, enter +{"{country number}"}
        {"{phone number}"} without a hyphen.
        <br />
        <code>ex) +821012345678</code>
        <br />
      </div>
    </>
  ),
  "후원자의 한마디를 남겨주세요!": "Leave a message for the donation!",
  "선택 안함": "Not selected",
  "파이콘 한국 2024 컨퍼런스 티켓 (10월 26-27일)":
    "PyCon Korea 2024 Conference ticket (October 26-27)",
  "파이콘 한국 2024 컨퍼런스 개인 후원 티켓 (10월 26-27일)":
    "PyCon Korea 2024 Conference individual donation ticket (October 26-27)",
  "파이콘 한국 2024 컨퍼런스 티켓 (10월 26-27일) [internal test]":
    "PyCon Korea 2024 Conference ticket (October 26-27)",
  "파이콘 한국 2024 컨퍼런스 개인 후원 티켓 (10월 26-27일) [internal test]":
    "PyCon Korea 2024 Conference individual donation ticket (October 26-27)",
};

export default EnglishTranslation;
