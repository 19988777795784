import React, { useState } from "react"

import { ErrorFallbackRenderer } from "components/ErrorFallbackRenderer"
import BuyTicket from "./buy"
import PaymentResult from "./paymentResult"

type State = {
  view: "LIST" | "PAYMENT_RESULT"
}

const TicketPage: React.FC = () => {
  const [view, setView] = useState<State["view"]>("LIST")
  switch (view) {
    case "LIST":
      return <BuyTicket onPaymentCompleted={() => setView("PAYMENT_RESULT")} />
    case "PAYMENT_RESULT":
      return <PaymentResult />
    default:
      return <ErrorFallbackRenderer>
        알 수 없는 문제가 발생했어요,<br />
        새로고침 후 다시 시도해주세요.
      </ErrorFallbackRenderer>
  }
}

export default TicketPage
