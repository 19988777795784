import { mdiAccountCreditCard, mdiTicket } from "@mdi/js"

export type MenuItem = {
  name: string;
  icon: string; // mdi icon은 SVG string입니다.
  sub?: {
    name: string;
    path: string;
  }[]
}

export type MenuList = {[key: string]: MenuItem};

const Menus: MenuList = {
  ticket: {
    name: "티켓",
    icon: mdiTicket,
  },
  "purchase-history": {
    name: "구매 내역",
    icon: mdiAccountCreditCard,
  },
} as const;

export default Menus;
