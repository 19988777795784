import { mdiLogin, mdiLogout, mdiOpenInNew, mdiTranslate } from "@mdi/js"
import Icon from "@mdi/react"
import { wrap } from "@suspensive/react"
import { useQueryClient } from "@tanstack/react-query"
import Hamburger from "hamburger-react"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"
import styled from "styled-components"

import { logoutSession } from "api_v2/user"
import { Slogan as SloganSvg } from "assets/icons"
import { RootState } from "store"
import { setLanguage } from "store/Core"
import { useUserEmail } from "utils/hooks/useAPIs"
import useIsMobile from "utils/hooks/useIsMobile"
import useTranslation from "utils/hooks/useTranslation"
import MenuRoutes, { MenuItem } from "./menus"

const LogInBtn: React.FC<{ onClick?: React.MouseEventHandler }> = ({ onClick }) => {
  const t = useTranslation()
  return <SubMenu className="sub-menu-item" onClick={onClick}>
    <Icon path={mdiLogin} size={0.75} /> {t("로그인")}
  </SubMenu>
}

const LogOutBtn: React.FC<{ onClick?: React.MouseEventHandler }> = ({ onClick }) => {
  const t = useTranslation()
  const queryClient = useQueryClient()
  const onBtnClick: React.MouseEventHandler<HTMLDivElement> = async (evt) => {
    await logoutSession()
    onClick?.(evt)
    queryClient.invalidateQueries()
  }
  return <SubMenu className="sub-menu-item" onClick={onBtnClick}>
    <Icon path={mdiLogout} size={0.75} /> {t("로그아웃")}
  </SubMenu>
}


const Nav = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const language = useSelector<RootState, RootState["core"]["language"]>(
    (state) => state.core.language
  )
  const [openMenu, setOpenMenu] = useState(false)
  const t = useTranslation()

  const closeAndNavigate = (path: string) => {
    setOpenMenu(false)
    navigate(path)
  }
  const closeAndSetLanguage = (lang: "KOR" | "ENG") => {
    setOpenMenu(false)
    dispatch(setLanguage(lang))
  }

  const NavMenuItem: React.FC<{ basePath: string; menuItem: MenuItem }> = ({ basePath, menuItem }) => {
    const menuGoto = () => closeAndNavigate(`/${basePath}`)
    return <Menu className="menu-item" key={basePath} onClick={menuItem.sub ? undefined : menuGoto}>
      <span><Icon path={menuItem.icon} size={0.75} />&nbsp;{t(menuItem.name)}</span>
      {
        menuItem.sub && <SubMenus className="sub-menu">
          {menuItem.sub.map((subMenu) => (
            <SubMenu className="sub-menu-item" key={subMenu.path} onClick={() => closeAndNavigate(`/${basePath}/${subMenu.path}`)}>
              {t(subMenu.name)}
            </SubMenu>
          ))}
        </SubMenus>
      }
    </Menu>
  }
  const LogInOrOutBtn = wrap
    .ErrorBoundary({ fallback: <LogInBtn onClick={() => closeAndNavigate("/login")} /> })
    .Suspense({ fallback: <LogInBtn onClick={() => closeAndNavigate("/login")} /> })
    .on(() => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const userEmailQuery = useUserEmail()
      return userEmailQuery.data
        ? <LogOutBtn onClick={() => closeAndNavigate("/")} />
        : <LogInBtn onClick={() => closeAndNavigate("/login")} />
    })

  return (
    <Container className="nav-bar">
      <div className="nav-logo" onClick={() => navigate("/")}>
        <SloganSvg />
      </div>
      {isMobile && <Hamburger toggled={openMenu} toggle={setOpenMenu} hideOutline={true} />}
      <div className={`menus ${isMobile && openMenu ? "visible" : "hidden"}`}>
        {Object.entries(MenuRoutes).map(([basePath, menuItem]) => <NavMenuItem {...{ key: basePath, basePath, menuItem }} />)}
        <Menu className="menu-item border-bottom"><LogInOrOutBtn /></Menu>
        <Menu className="menu-item">
          <span><Icon path={mdiTranslate} size={0.75} />&nbsp;{t("언어")}</span>
          <SubMenus className="sub-menu">
            <SubMenu className="sub-menu-item" onClick={() => closeAndSetLanguage("KOR")}>한국어</SubMenu>
            <SubMenu className="sub-menu-item" onClick={() => closeAndSetLanguage("ENG")}>English</SubMenu>
          </SubMenus>
        </Menu>
        <a href="https://www.pycon.kr/" target="_blank" rel="noreferrer">
          <ReturnToConfSite>
            <Icon path={mdiOpenInNew} size={0.75} /> {t("PyCon 한국 홈페이지")}
          </ReturnToConfSite>
        </a>
      </div>
    </Container>
  )
}

export default Nav

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #141414;
`

const SubMenus = styled.div`
  display: none;
  position: absolute;
  top: 70%;

  background-color: #141414;

  font-size: initial;
  font-weight: initial;
`

const SubMenu = styled.div`
  cursor: pointer;

  padding: 0.1rem 0.2rem;

  color: #febd99;

  & + & {
    margin-top: 0.2rem;
  }

  &:hover {
    color: #b0a8fe;
  }
`

const Menu = styled.div`
  position: relative;
  cursor: pointer;
  user-select: none;

  padding: 1vh 1vw;

  &:hover {
    color: #b0a8fe;
    & > ${SubMenus} {
      display: initial;
    }
  }
`

const ReturnToConfSite = styled.button`
  cursor: pointer;

  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  margin: 1vh 0 1vh 1vw;

  color: #febd99;
  background-color: #141414;

  border: 1px solid #febd99;
  border-radius: 0.5rem;

  &:hover {
    color: #b0a8fe;
    border: 1px solid #b0a8fe;
  }
`
