import React from "react"
import styled from "styled-components"

import { mdiOpenInNew } from "@mdi/js"
import Icon from "@mdi/react"
import { Link } from "react-router-dom"
import useTranslation from "utils/hooks/useTranslation"

const Footer = () => {
  const t = useTranslation();

  return (
    <Container>
      <div className="footer-about-section halftone">
        <FooterContent>
          <strong>사단법인 파이썬사용자모임</strong>
          <br />
          서울특별시 강남구 강남대로84길 24-4
          <Bar />
          대표자명 : 배권한
          <Bar />
          대표 전화 번호 : 031-261-2203, 010-5298-6622, 010-8259-3013 (문자)
          <Bar />
          사업자 등록 번호 : 338-82-00046&nbsp;
          <a
            href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=3388200046"
            target="_blank"
            rel="noreferrer"
          >
            <button>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Icon path={mdiOpenInNew} size={0.5} />
                사업자 정보 확인
              </div>
            </button>
          </a>
          <br />
          통신 판매 번호 : 2023-서울강남-03501
          <Bar />
          호스팅 제공자 : Amazon Web Services(Korea LLC)
          <br />
          문의: <a href="mailto:pyconkr@pycon.kr">pyconkr@pycon.kr</a>
          <br />
          <div style={{ color: "rgb(96, 96, 255)" }}>
            <Link to="/terms-of-service">{t("서비스 이용 약관")}</Link>
            <Bar />
            <Link to="/privacy-policy">{t("개인 정보 처리 방침")}</Link>
          </div>
          <br />
          Made with Django, DRF, and ❤️ by PyCon Korea Organizing Team.
          <br />© 2024, 파이콘 한국 준비위원회, All rights reserved.
        </FooterContent>
      </div>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  background: linear-gradient(0deg, rgba(117, 64, 191, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  width: 100%;
`;

const FooterContent = styled.div`
  padding: 0 2rem;
  margin: 0.1rem;

  font-size: 10pt;
  color: #777;

  a > button {
    margin: 0;
    padding: 0;
    font-size: 8pt;
  }

  strong {
    font-size: 12pt;
  }

  @media (max-width: 809px) {
    font-size: 9pt;

    a > button {
      margin: 0;
      padding: 0;
      font-size: 7pt;
    }

    strong {
      font-size: 12pt;
    }
  }
`;

const Bar = () => (
  <div style={{ display: "inline-block", padding: "0 0.25rem", color: "#777" }}>|</div>
);
