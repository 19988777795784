import { useMutation, useSuspenseQuery } from '@tanstack/react-query'
import * as R from 'remeda'

import { addItemToCart, fetchCart, removeItemFromCart } from 'api_v2/cart'
import { fetchOrders, modifyOrderOptions, prepareOneItemOrder, prepareOrder } from 'api_v2/order'
import { fetchProduct, fetchProducts } from 'api_v2/product'
import { refundAllItemsInOrder, refundOneItemFromOrder } from 'api_v2/refund'
import { getUserInfo, redirectToProvider, signInWithEmail } from 'api_v2/user'

export const QUERY_KEYS = {
  USER_EMAIL: ['query', 'user', 'email'],
  PRODUCT_LIST: ['query', 'products'],
  PRODUCT: ['query', 'product'], // Please add productId at the end of this key
  CART_INFO: ['query', 'cart'],
  ORDER_LIST: ['query', 'orders'],
}

export const MUTATION_KEYS = {
  SNS_PROVIDER: ['mutation', 'sns', 'provider'],
  EMAIL_SIGN_IN: ['mutation', 'email', 'sign_in'],
  CART_ITEM_APPEND: ['mutation', 'cart', 'item', 'append'],
  CART_ITEM_REMOVE: ['mutation', 'cart', 'item', 'remove'],
  ORDER: ['mutation', 'order', 'start'],
  ONE_ITEM_ORDER: ['mutation', 'one_item_order', 'start'],
  ONE_ITEM_REFUND: ['mutation', 'one_item_refund'],
  ORDER_REFUND: ['mutation', 'order_refund'],
  ORDER_OPTIONS_MODIFY: ['mutation', 'order', 'options', 'modify'],
}

export const useSNSProviderMutation = () => useMutation({
  mutationKey: MUTATION_KEYS.SNS_PROVIDER,
  mutationFn: redirectToProvider,
})

export const useEmailSignInMutation = () => useMutation({
  mutationKey: MUTATION_KEYS.EMAIL_SIGN_IN,
  mutationFn: signInWithEmail,
})

export const useUserEmail = () => useSuspenseQuery({
  queryKey: QUERY_KEYS.USER_EMAIL,
  queryFn: async () => {
    try {
      const userEmail = (await getUserInfo()).data?.user?.email || ''
      return (R.isString(userEmail) && !R.isEmpty(userEmail)) ? userEmail : null
    } catch (e) {
      return null
    }
  },
})

export const useProducts = (categoryInfo?: { category_group?: string; category?: string }) => useSuspenseQuery(
  { queryKey: QUERY_KEYS.PRODUCT_LIST, queryFn: () => fetchProducts(categoryInfo) }
)

export const useProduct = (productId: string) => useSuspenseQuery({ queryKey: [...QUERY_KEYS.PRODUCT, productId], queryFn: () => fetchProduct(productId) })

export const useCart = () => useSuspenseQuery({ queryKey: QUERY_KEYS.CART_INFO, queryFn: fetchCart })

export const useAddItemToCartMutation = () => useMutation({ mutationKey: MUTATION_KEYS.CART_ITEM_APPEND, mutationFn: addItemToCart })

export const useRemoveItemFromCartMutation = () => useMutation({ mutationKey: MUTATION_KEYS.CART_ITEM_REMOVE, mutationFn: removeItemFromCart })

export const useOrders = () => useSuspenseQuery({ queryKey: QUERY_KEYS.ORDER_LIST, queryFn: fetchOrders })

export const usePrepareOneItemOrderMutation = () => useMutation({ mutationKey: MUTATION_KEYS.ONE_ITEM_ORDER, mutationFn: prepareOneItemOrder })

export const usePrepareOrderMutation = () => useMutation({ mutationKey: MUTATION_KEYS.ORDER, mutationFn: prepareOrder })

export const useOneItemRefundMutation = () => useMutation({ mutationKey: MUTATION_KEYS.ONE_ITEM_REFUND, mutationFn: refundOneItemFromOrder })

export const useOrderRefundMutation = () => useMutation({ mutationKey: MUTATION_KEYS.ORDER_REFUND, mutationFn: refundAllItemsInOrder })

export const useOrderOptionsModifyMutation = () => useMutation({ mutationKey: MUTATION_KEYS.ORDER_OPTIONS_MODIFY, mutationFn: modifyOrderOptions })
