import { RequestPayResponse } from "iamport-typings/src"

import { Order } from "models/order"
import { getOrderNameFromOrder } from "utils/apis/orders"

export const startPortOnePurchase = (
  order: Order,
  onSuccess?: (response: RequestPayResponse) => void,
  onFailure?: (response: RequestPayResponse) => void,
  onCleanUp?: (response: RequestPayResponse) => void,
) => {
  const { IMP } = window
  const portOneAccountId = process.env.REACT_APP_PORTONE_ACCOUNT_ID
  if (!IMP || !portOneAccountId) {
    alert('PortOne 라이브러리가 로드되지 않았습니다.')
    return
  }

  IMP.init(portOneAccountId)
  IMP.request_pay(
    {
      pg: "kcp",
      pay_method: "card",
      merchant_uid: order.id,
      name: getOrderNameFromOrder(order),
      amount: order.first_paid_price,
      buyer_tel: "",
    },
    async (response: RequestPayResponse) => {
      if (response.success) onSuccess?.(response)
      else onFailure?.(response)
      onCleanUp?.(response)
    }
  )
}
