import { PaymentRequest } from "api_v2"
import { OneItemOrderRequest, OrderOptionsModifyRequest } from "models/api/order"
import { Order } from "models/order"

export const fetchOrders = async (): Promise<Order[]> => (
  await PaymentRequest({ route: 'v1/orders/', method: 'GET' })
).json()

export const prepareOneItemOrder = async (payload: OneItemOrderRequest) => {
  const response = await PaymentRequest({ route: 'v1/orders/single/', method: 'POST', data: payload })
  return (await response.json()) as Order
}

export const prepareOrder = async () => {
  const response = await PaymentRequest({ route: 'v1/orders/', method: 'POST' })
  return (await response.json()) as Order
}

export const modifyOrderOptions = async (payload: OrderOptionsModifyRequest) => {
  const response = await PaymentRequest({
    route: `v1/orders/${payload.orderId}/products/${payload.orderProductRelationId}/options/`,
    method: 'PATCH',
    data: payload.options
  })
  return (await response.json()) as Order
}
