import React from 'react'
import './loading.scss'

export const LoadingIcon: React.FC<{ emSize?: number }> = ({ emSize = 2 }) => {
  const circleStyle: React.CSSProperties = {
    width: `${emSize}em`,
    height: `${emSize / 2}em`,
  }
  return (
    <div className="loadingIcon">
      <div className="spinner">
        <div className="circle circle-1" style={circleStyle}>
          <div className="circle-inner" />
        </div>
        <div className="circle circle-2" style={circleStyle}>
          <div className="circle-inner" />
        </div>
      </div>
    </div>
  )
}
