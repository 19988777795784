import * as R from 'remeda'

import { APIErrorReasonResponseType, APIErrorResponseType } from 'models/api/common'
import { isAllTrue } from 'utils/arrays'

export const isJsonParseable = (data: unknown): boolean => {
  try {
    JSON.parse(data as string)
    return true
  } catch (e) {
    return false
  }
}

export const isAPIErrorResponseType = (data: unknown): data is APIErrorResponseType => {
  const errReasonObjChecker = R.allPass<APIErrorReasonResponseType>([
    R.isObjectType,
    (d) => R.isString(d.code),
    (d) => R.isString(d.detail),
    (d) => !R.isDefined(d.attr) || R.isString(d.attr),
  ])

  const errObjChecker = R.allPass<APIErrorResponseType>([
    R.isObjectType,
    (d) => R.isString(d.type),
    (d) => R.isArray(d.errors),
    (d) => R.allPass(d.errors, [
      R.isArray,
      (de) => R.hasAtLeast(de, 1),
      (de) => isAllTrue(de.map(errReasonObjChecker)),
    ]),
  ])

  try {
    return errObjChecker(data as APIErrorResponseType)
  } catch (e) {
    return false
  }
}
