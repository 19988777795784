import { PaymentRequest } from "api_v2"
import { Product } from "models/product"

export const fetchProducts = async (categoryInfo?: { category_group?: string; category?: string }): Promise<Product[]> => {
  const categoryQueryParamStr: string = categoryInfo ? `?${(new URLSearchParams(categoryInfo)).toString()}` : ''
  const response = await PaymentRequest({
    route: `v1/products/${categoryQueryParamStr}`,
    method: 'GET',
    requiresAuth: false,
  })
  return response.json()
}

export const fetchProduct = async (productId: string): Promise<Product> => (
  await PaymentRequest({ route: `v1/products/${productId}/`, method: 'GET', requiresAuth: false })
).json()
