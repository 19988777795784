import { Order, OrderProductCustomResponseOptions, OrderProductOptionsType } from "models/order"

export const getOrderNameFromOrder = (order: Order): string => {
  return order.products.length === 0
    ? "알 수 없는 구매 이력"
    : order.products[0].product.name + (order.products.length - 1 === 0 ? '' : ` 외 ${order.products.length - 1}개`)
}

/**
 * is_custom_response인 옵션을 우선적으로 노출되도록 정렬합니다.
 * @param a 비교할 OrderProductOptionsType
 * @param b 비교할 OrderProductOptionsType
 */
export const compareOrderProductOptions = (a: OrderProductOptionsType, b: OrderProductOptionsType) => {
  if (a.product_option_group.is_custom_response === b.product_option_group.is_custom_response) return 0
  return a.product_option_group.is_custom_response ? -1 : 1
}

/**
 * 주어진 옵션 그룹이 custom response를 받는 그룹인지 확인합니다.
 * @param option 확인할 OrderProductOptionsType
 * @returns option이 custom response를 받는 그룹이면 true
 */
export const isCustomResponseOptionGroup = (option: OrderProductOptionsType): option is OrderProductCustomResponseOptions => {
  return option.product_option_group.is_custom_response
}
