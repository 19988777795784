import * as R from 'remeda'
import { isAPIErrorResponseType, isJsonParseable } from 'utils/apis/common'

const DEFAULT_TIMEOUT = 15 * 1000 // 15 seconds

export type RequestFetchContentType = 'application/json' | 'application/x-www-form-urlencoded'
export type RequestFetchMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
export type RequestFetchArguments = {
  route: string
  method: RequestFetchMethod
  requiresAuth?: boolean
  ignoreAuthError?: boolean
  redirect?: 'follow' | 'error' | 'manual'
  mode?: 'cors' | 'no-cors' | 'same-origin'
} & (
  {
    contentType?: 'application/json'
    data?: Record<string, unknown> | Record<string, unknown>[]
  } | {
    contentType: 'application/x-www-form-urlencoded'
    data: Record<string, string>
  }
)

export class RequestError extends Error {
  readonly requiresAuth: boolean
  readonly status: number
  readonly payload: string

  constructor(message: string, requiresAuth: boolean, status: number, payload: string) {
    super(message)
    this.name = 'RequestError'
    this.requiresAuth = requiresAuth
    this.status = status
    this.payload = payload
  }

  toAlertString() {
    if (this.requiresAuth) return this.message

    if (isJsonParseable(this.payload)) {
      const errObj = JSON.parse(this.payload)
      return ((isAPIErrorResponseType(errObj) && R.first(errObj.errors)?.detail) || errObj.message) ?? this.message
    }

    return this.message
  }
}

export const PaymentRequest = async (reqOption: RequestFetchArguments) => {
  const headers = new Headers({
    'Content-Type': reqOption.contentType ?? 'application/json',
    'X-CSRFToken': getCSRFToken() ?? '',
  })

  let payload: BodyInit | URLSearchParams | undefined = undefined

  if (['POST', 'PUT', 'PATCH'].includes(reqOption.method) && reqOption.data) {
    if (reqOption.contentType === 'application/x-www-form-urlencoded') {
      payload = new URLSearchParams({
        ...reqOption.data,
        csrfmiddlewaretoken: getCSRFToken() ?? '',
      })
    } else if (headers.get('Content-Type') === 'application/json') {
      payload = JSON.stringify(reqOption.data ?? {}) as BodyInit
    }
  }

  const result = await fetch(`${process.env.REACT_APP_PYCONKR_API}/${reqOption.route}`, {
    method: reqOption.method,
    cache: 'no-cache',
    redirect: 'follow',
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
    headers: headers,
    credentials: 'include',
    referrerPolicy: 'origin',
    mode: reqOption.mode ?? 'cors',
    ...(['POST', 'PUT', 'PATCH'].includes(reqOption.method) ? { body: payload } : {}),
  })
  if (!result.ok && result.status !== 0) {
    const isAuthError = result.status === 401 || result.status === 403
    if (reqOption.ignoreAuthError && isAuthError) return result
    throw new RequestError(
      isAuthError ? '로그인이 필요합니다.' : '요청에 실패했습니다.',
      isAuthError,
      result.status,
      await result.text(),
    )
  }
  return result
}

export const defaultRequestErrorHandler = (e: Error) => alert((e instanceof RequestError) ? e.toAlertString() : e.message)

const getCookie = (name: string) => {
  if (!R.isString(document.cookie) || R.isEmpty(document.cookie))
    return undefined

  let cookieValue: string | undefined
  document.cookie.split(';').forEach((cookie) => {
    if (R.isEmpty(cookie) || !cookie.includes('=')) return
    const [key, value] = cookie.split('=')
    if (key.trim() === name) cookieValue = decodeURIComponent(value) as string
  })
  return cookieValue
}

export const getCSRFToken = () => getCookie(process.env.REACT_APP_PYCONKR_CSRF_COOKIE_NAME as string)
