import React from "react"
import styled from "styled-components"

type ErrorBoundaryProps = React.PropsWithChildren & {
  error?: Error
  reset?: () => void
}

export const ErrorFallbackRenderer: React.FC<ErrorBoundaryProps> = ({ children, reset }) => {
  return <ErrorDialog open>
    <ErrorDialogBox>
      {children}
      <br />
      <ErrorDialogButton onClick={reset}>재시도</ErrorDialogButton>
    </ErrorDialogBox>
  </ErrorDialog>
}

const ErrorDialog = styled.dialog`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.2);
`

const ErrorDialogBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 1rem;

  text-align: center;
  font-weight: bold;
`

const ErrorDialogButton = styled.button`
  border: none;
  border-radius: 12px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-weight: bold;
  color: white;
  background-color: rgba(255, 90, 95, 0.5);
  margin-top: 1rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(255, 90, 95, 1);
  }
`
