import { PaymentRequest } from "api_v2"
import { CartItemAppendRequest } from "models/api/order"
import { Order } from "models/order"

export const fetchCart = async (): Promise<Order[]> => (
  await PaymentRequest({ route: 'v1/orders/cart/', method: 'GET' })
).json()

export const addItemToCart = async (payload: CartItemAppendRequest) =>
  await PaymentRequest({ route: 'v1/orders/cart/products/', method: 'POST', data: payload })


export const removeItemFromCart = async (productId: string) =>
  await PaymentRequest({ route: `v1/orders/cart/products/${productId}/`, method: 'DELETE' })

