import { wrap } from '@suspensive/react'
import { useQueryClient, useQueryErrorResetBoundary } from '@tanstack/react-query'
import React from 'react'
import styled from 'styled-components'

import { defaultRequestErrorHandler } from 'api_v2'
import { ErrorFallbackRenderer } from 'components/ErrorFallbackRenderer'
import { LoadingIcon } from 'components/common/Element/loading'
import Page from 'components/common/Page'
import { PriceDisplay } from 'components/common/PriceDisplay'
import { SignInHelmetGenerator } from 'components/common/SignInHelmet'
import { Order, OrderProductItem, OrderProductOptionsType } from 'models/order'
import { compareOrderProductOptions, getOrderNameFromOrder, isCustomResponseOptionGroup } from 'utils/apis/orders'
import { getFormValue } from 'utils/form'
import { QUERY_KEYS, useOrderOptionsModifyMutation, useOrderRefundMutation, useOrders, useUserEmail } from 'utils/hooks/useAPIs'
import useTranslation from 'utils/hooks/useTranslation'
import { isFilledString } from 'utils/strings'

const PurchaseHistoryProductOptionRow: React.FC<{
  orderProductOptions: OrderProductOptionsType
  disabled?: boolean
}> = ({ orderProductOptions, disabled }) => {
  const t = useTranslation()
  isCustomResponseOptionGroup(orderProductOptions)

  const customResponsePattern = isFilledString(orderProductOptions.product_option_group.custom_response_pattern)
    ? new RegExp(orderProductOptions.product_option_group.custom_response_pattern, 'g')
    : undefined

  const isInputDisabled = disabled || !(
    orderProductOptions.product_option_group.response_modifiable_ends_at
    && new Date(orderProductOptions.product_option_group.response_modifiable_ends_at) > new Date()
  )

  return <tr>
    <td></td>
    <TableCellForHidingInfo>{t(orderProductOptions.product_option_group.name)}</TableCellForHidingInfo>
    {
      isCustomResponseOptionGroup(orderProductOptions)
        ? <td colSpan={2}>
          <input
            disabled={isInputDisabled}
            pattern={customResponsePattern?.source}
            name={orderProductOptions.id}
            defaultValue={orderProductOptions.custom_response}
          />
        </td>
        : <>
          <td>{t(orderProductOptions.product_option.name)}</td>
          <td>
            {
              orderProductOptions.product_option.additional_price > 0
              && <PriceDisplay price={orderProductOptions.product_option.additional_price} />
            }
          </td>
        </>
    }
  </tr>
}


const PurchaseHistoryProduct: React.FC<{
  order: Order
  orderProduct: OrderProductItem
  disabled?: boolean
}> = ({ order, orderProduct, disabled }) => {
  const t = useTranslation()
  const queryClient = useQueryClient()
  const optionsModifyMutation = useOrderOptionsModifyMutation()
  const formRef = React.useRef<HTMLFormElement>(null)

  const isModifiable = order.current_status === 'completed' && orderProduct.status === 'paid' && !disabled

  const modifyOptions: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.preventDefault()
    evt.stopPropagation()

    if (!formRef.current) return
    if (!formRef.current.checkValidity()) {
      formRef.current.reportValidity()
      return
    }
    const formData = getFormValue<{ [k: string]: string }>({ form: formRef.current })
    const payload = Object.entries(formData).map(([key, value]) => ({
      order_product_option_relation: key,
      custom_response: value,
    }))
    optionsModifyMutation.mutate({
      orderId: order.id,
      orderProductRelationId: orderProduct.id,
      options: payload,
    }, {
      onSuccess: () => {
        alert('옵션 수정이 완료되었습니다.')
        queryClient.invalidateQueries({ queryKey: QUERY_KEYS.ORDER_LIST })
      },
      onError: defaultRequestErrorHandler,
    })
  }

  return <form ref={formRef}>
    <PurchaseHistoryProductTable>
      <colgroup>
        <col style={{ width: '5%' }} />
        <col style={{ width: '15%' }} />
        <col style={{ width: '60%' }} />
        <col style={{ width: '20%' }} />
      </colgroup>
      <tr>
        <td colSpan={3}>{t(orderProduct.product.name)}</td>
        <td><PriceDisplay price={orderProduct.price + orderProduct.donation_price} /></td>
      </tr>
      {
        orderProduct.options
        .sort(compareOrderProductOptions)
        .map(
          (option, index) => <PurchaseHistoryProductOptionRow
            key={index}
            orderProductOptions={option}
            disabled={!isModifiable}
          />
        )
      }
      <tr>
        <td colSpan={4}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <small style={{ fontSize: '8pt', textAlign: 'left' }}>
              * {t('수정 가능일이 지나지 않은 일부 옵션만 수정하실 수 있습니다.')}
              <br />
              * {t('영수증 관련 문의는 ')}
              <a href="mailto:pyconkr@pycon.kr" style={{ color: '#febd99' }}>pyconkr@pycon.kr</a>
              {t('로 문의해주세요.')}
            </small>
            {
              isModifiable && <button onClick={modifyOptions} disabled={optionsModifyMutation.isPending}>
                {t('옵션 수정')}
              </button>
            }
          </div>
        </td>
      </tr>
    </PurchaseHistoryProductTable>
  </form>
}

const PurchaseHistoryListRow: React.FC<{ order: Order; disabled: boolean }> = ({ order, disabled }) => {
  const t = useTranslation()
  const queryClient = useQueryClient()
  const refundOrder = useOrderRefundMutation()
  const productName = getOrderNameFromOrder(order)
  const purchasedAt = new Date(order.created_at).toLocaleString()
  const isRefundable = !disabled && !refundOrder.isPending && (order.current_status === 'completed' || order.current_status === 'partial_refunded')

  const onClick = () => {
    refundOrder.mutate(order.id, {
      onSuccess: () => {
        alert('환불이 완료되었습니다.')
        queryClient.invalidateQueries({ queryKey: QUERY_KEYS.ORDER_LIST })
      },
      onError: defaultRequestErrorHandler,
    })
  }

  const pyconDomain = process.env.REACT_APP_PYCONKR_REAL_API ?? process.env.REACT_APP_PYCONKR_API ?? 'https://shop-api.pycon.kr'
  const receiptUrl = `${pyconDomain}/v1/orders/${order.id}/receipt/`

  return <>
    <tr>
      <td>{order.current_status}</td>
      <td>{t(productName)}</td>
      <td>{purchasedAt}</td>
      <td>
        <PriceDisplay price={order.first_paid_price} />
        {
          order.first_paid_price !== order.current_paid_price && <>
            <br />
            <small><PriceDisplay price={order.current_paid_price} label="남은 금액" /></small>
          </>
        }
      </td>
      <td>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '0.5rem' }}>
          <button disabled={!isRefundable} style={{ backgroundColor: 'var(--pico-background-color)', width: '100%' }} onClick={() => window.open(order.scancode_url, '_blank')}>
            {t('입장 QR 코드')}
          </button>
          <button style={{ backgroundColor: '#7d9211', width: '100%' }} onClick={() => window.open(receiptUrl, '_blank')}>
            {t('영수증')}
          </button>
          <button onClick={onClick} style={{ width: '100%' }} disabled={!isRefundable}>{refundOrder.isPending ? <LoadingIcon /> : t('환불')}</button>
        </div>
      </td>
    </tr>
    <tr>
      <td colSpan={5} style={{ padding: '0' }}>
        <details style={{ margin: '0' }}>
          <summary
            role="button"
            className="secondary"
            style={{
              height: '1.5rem',
              padding: '0 1rem',
              margin: '0',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              border: 'none',
              fontSize: '0.8rem',
              lineHeight: '1.5rem',
            }}
          >{t('상세 정보')}</summary>
          {
            order.products.map(
              (product) => <PurchaseHistoryProduct key={product.id} order={order} orderProduct={product} disabled={!isRefundable} />
            )
          }
        </details>
      </td>
    </tr>
  </>
}

const PaymentList = () => {
  const { reset } = useQueryErrorResetBoundary()

  const PurchaseHistories = wrap
    .ErrorBoundary({
      onReset: reset,
      fallback: (props) => <ErrorFallbackRenderer {...props}>
        구매 내역을 불러오는 중 문제가 발생했어요,<br />
        잠시 후 다시 시도해주세요.
      </ErrorFallbackRenderer>,
    })
    .Suspense({ fallback: <LoadingIcon /> })
    .on(() => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const userEmailQuery = useUserEmail()
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const orderQuery = useOrders()
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const t = useTranslation()

      const purchaseHistoryRows = orderQuery.data.map((order) => (
        <PurchaseHistoryListRow key={order.id} order={order} disabled={orderQuery.isFetching} />
      ))

      return <>
        <h1>{t('결제 내역')} <small>({userEmailQuery.data})</small></h1>
        <small style={{ color: '#aaa' }}>
          * {t('상품 주문 후 노출에 최대 3분 정도 소요될 수 있어요, 만약 3분 후에도 노출되지 않을 경우 파이콘 한국 준비위원회에 문의해주세요.')}
        </small>
        <table>
          <thead>
            <tr>
              <th>{t('결제 상태')}</th>
              <th>{t('주문명')}</th>
              <th>{t('결제 일시')}</th>
              <th>{t('금액')}</th>
              <th>{t('옵션')}</th>
            </tr>
          </thead>
          <tbody>{purchaseHistoryRows}</tbody>
        </table>
      </>
    })

  const SignInHelmet = SignInHelmetGenerator(reset)

  return (
    <Page>
      <Container>
        <SignInHelmet alertText="로그인이 필요한 페이지입니다.">
          <PurchaseHistories />
        </SignInHelmet>
      </Container>
    </Page>
  )
}

export default PaymentList

const Container = styled.div`
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  th,
  td {
    text-align: center;
    padding: 8px;
  }

  button {
    background-color: #871007;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
`

const PurchaseHistoryProductTable = styled.table`
  margin: 0;

  th, td {
    background-color: rgba(96, 96, 96, 0.2);
    text-align: start;
    padding: 0.5rem 1rem;

    input {
      margin: 0;
      padding: 0 0.5rem;
      height: 2rem;

      background-color: rgba(96, 96, 96, 0.4);
      font-size: 1rem;

      &[disabled] {
        cursor: not-allowed;
        pointer-events: unset;
      }
    }

    button {
      background-color: var(--pico-background-color);
      font-size: 10pt;
    }

    &:first-of-type {
      font-weight: bold;
    }

    &:not(:first-of-type, :last-of-type) {
      font-size: 0.8rem;
    }

    &:last-of-type {
      text-align: end;
    }
  }
`

const TableCellForHidingInfo = styled.td`
  .hideOnHistory {
    display: none;
  }
`
