import { wrap } from "@suspensive/react";
import { useQueryClient, useQueryErrorResetBoundary } from "@tanstack/react-query";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";

import { defaultRequestErrorHandler } from "api_v2";
import { ErrorFallbackRenderer } from "components/ErrorFallbackRenderer";
import { LoadingIcon } from "components/common/Element/loading";
import Page from "components/common/Page";
import { PriceDisplay } from "components/common/PriceDisplay";
import {
  DialogButtonPropType,
  INITIAL_DIALOG_PRODUCT_PROP,
  ProductInfoDialog,
} from "components/common/ProductInfoDialog";
import { Order } from "models/order";
import { Product } from "models/product";
import { startPortOnePurchase } from "utils/externals/portOne";
import { getOrderProductInfoFromForm } from "utils/form";
import { usePrepareOneItemOrderMutation, useProducts, useUserEmail } from "utils/hooks/useAPIs";
import useTranslation from "utils/hooks/useTranslation";

type Props = {
  onPaymentCompleted: () => void;
};

const ProductListRow: React.FC<{
  onOrderButtonClick: (product: Product) => void;
  product: Product;
}> = ({ onOrderButtonClick, product }) => {
  const t = useTranslation();
  return (
    <tr>
      <td>{t(product.name)}</td>
      <td>
        <PriceDisplay price={product.price} />
      </td>
      <td>
        <button onClick={() => onOrderButtonClick(product)}>{t("구매")}</button>
      </td>
    </tr>
  );
};

const BuyTicket = ({ onPaymentCompleted }: Props) => {
  const t = useTranslation();
  const { reset } = useQueryErrorResetBoundary();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const optionFormRef = React.createRef<HTMLFormElement>();
  const donationInputRef = React.createRef<HTMLInputElement>();
  const orderOneItemMutation = usePrepareOneItemOrderMutation();
  const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);
  const [selectedProduct, setProduct] = useState<Product>(INITIAL_DIALOG_PRODUCT_PROP);

  const setProductAndOpenDialog = (product: Product) => {
    setProduct(product);
    setIsDialogOpened(true);
  };

  const ProductList = wrap
    .ErrorBoundary({
      onReset: reset,
      fallback: (props) => (
        <ErrorFallbackRenderer {...props}>
          티켓 목록을 불러오는 중 문제가 발생했어요,
          <br />
          잠시 후 다시 시도해주세요.
        </ErrorFallbackRenderer>
      ),
    })
    .Suspense({ fallback: <LoadingIcon /> })
    .on(() => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const userEmailQuery = useUserEmail();
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const productQuery = useProducts({ category_group: new Date().getFullYear().toString() });
      if (productQuery.data.length === 0)
        return (
          <div>
            <h4>
              현재는 구매 가능한 티켓이 없습니다,
              <br />
              나중에 다시 찾아와주세요!
            </h4>
          </div>
        );

      const dialogSignInHelmet = (product: Product) => {
        if (userEmailQuery.data) return setProductAndOpenDialog(product);
        if (
          window.confirm(
            t(
              "티켓을 구매하려면 먼저 로그인이 필요합니다.\n로그인 페이지로 이동하시겠습니까?"
            ) as string
          )
        )
          return navigate("/login");
      };

      const productList = productQuery.data.map((product) => (
        <ProductListRow
          key={product.id}
          onOrderButtonClick={dialogSignInHelmet}
          product={product}
        />
      ));

      return (
        <>
          <table>
            <thead>
              <tr>
                <td>{t("상품명")}</td>
                <td>{t("가격")}</td>
                <td>{t("구매")}</td>
              </tr>
            </thead>
            <tbody>{productList}</tbody>
          </table>

          <h3>{t("컨퍼런스 티켓 관련 안내")}</h3>
          <ul>
            <li>
              {t(
                '기업/단체 명의의 계좌 이체 및 세금계산서 발행이 필요한 경우 "기업/단체 티켓"으로 구매하셔야 합니다.'
              )}
            </li>
            <ul>
              <li>
                {t("가격은 장당 16만 원입니다.")} (
                {t("기업/단체에서의 파이썬 커뮤니티에 대한 관심과 후원의 의미가 있습니다")})
              </li>
              <li>
                {t('"기업/단체 티켓"은 메일')}(
                <a href="mailto:pyconkr@pycon.kr">pyconkr@pycon.kr</a>)
                {t("로 문의주시기 바랍니다.")}
              </li>
            </ul>
            <li>
              "{t("뭉치면 할인!")}": {t("5인 이상 단체로 구매하시는 경우 20% 할인이 됩니다.")}
            </li>
            <ul>
              <li>{t("계좌 이체로만 구매가 가능합니다. (현금영수증 가능)")}</li>
              <li>{t("할인 받은 티켓은 재정 지원 대상이 아닙니다.")}</li>
              <li>
                {t('"뭉치면 할인!" 티켓은 메일')}(
                <a href="mailto:pyconkr@pycon.kr">pyconkr@pycon.kr</a>)
                {t("로 문의주시기 바랍니다.")}
              </li>
            </ul>
          </ul>
        </>
      );
    });

  const onOrderOneItem = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const requestData = getOrderProductInfoFromForm(
      selectedProduct,
      optionFormRef,
      donationInputRef
    );
    if (!requestData) return;

    orderOneItemMutation.mutate(requestData, {
      onSuccess: (order: Order) => {
        startPortOnePurchase(
          order,
          () => {
            queryClient.invalidateQueries();
            queryClient.resetQueries();
            onPaymentCompleted();
            setIsDialogOpened(false);
          },
          (response) => alert("결제 실패!\n" + response.error_msg),
          () => {}
        );
      },
      onError: defaultRequestErrorHandler,
    });
  };

  const dialogActionButtons: DialogButtonPropType[] = [
    {
      labal: t("취소") as string,
      style: { backgroundColor: "rgba(255, 128, 128, 0.5)" },
      onClick: () => setIsDialogOpened(false),
    },
    {
      labal: t("구매하기") as string,
      style: { backgroundColor: "rgba(128, 255, 128, 0.5)" },
      onClick: onOrderOneItem,
    },
  ];

  return (
    <Page title="티켓 구매">
      <Container>
        <h1>{t("티켓 구매하기")}</h1>
        <ProductList />
        <ProductInfoDialog
          open={isDialogOpened}
          header={t("상품 정보")}
          product={selectedProduct}
          optionFormRef={optionFormRef}
          donationInputRef={donationInputRef}
          actionButtons={dialogActionButtons}
          disabled={orderOneItemMutation.isPending}
        />
      </Container>
    </Page>
  );
};

export default BuyTicket;

const Container = styled.div`
  width: 100%;

  table {
    th,
    td {
      text-align: center;
    }
    td:first-of-type {
      width: 25%;
      font-weight: bold;
    }
    td:nth-of-type(2) {
      width: 10%;
    }
    td:nth-of-type(3) {
      width: 10%;
    }
  }

  .cm-dialog {
    a {
      text-decoration: none;
      color: blue;
    }
  }
`;
