import React from "react";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";
import * as R from "remeda";
import styled from "styled-components";

import { OptionGroup, Product } from "models/product";
import useTranslation from "utils/hooks/useTranslation";
import { PriceDisplay } from "../PriceDisplay";

const TicketTOSPolicy: React.FC = () => {
  const t = useTranslation();
  const TOS = (
    <Link to="/terms-of-service" target="_blank">
      {t("서비스 이용 약관")}
    </Link>
  );
  const PrivacyPolicy = (
    <Link to="/privacy-policy" target="_blank">
      {t("개인 정보 처리 방침")}
    </Link>
  );
  return (
    <ProductTOSPolicyContainer>
      {t("상품을 구매하시면 관련")} {TOS} {t("및")} {PrivacyPolicy}{" "}
      {t("에 동의한 것으로 간주합니다.")}
    </ProductTOSPolicyContainer>
  );
};

export type DialogButtonPropType = {
  labal: string;
  style: React.CSSProperties;
  onClick: React.MouseEventHandler;
};

export type DialogPropType = {
  open: boolean;
  header: string | React.ReactNode;
  product: Product;
  optionFormRef?: React.RefObject<HTMLFormElement | null>;
  donationInputRef?: React.RefObject<HTMLInputElement | null>;
  actionButtons: DialogButtonPropType[];
  disabled?: boolean;
};

export const INITIAL_DIALOG_PRODUCT_PROP: Product = {
  id: "",
  name: "",
  description: "",
  price: 0,
  leftover_stock: 0,
  option_groups: [],

  orderable_starts_at: "",
  orderable_ends_at: "",
  refundable_ends_at: "",

  donation_allowed: false,
  donation_min_price: 0,
  donation_max_price: 0,

  image: "",
  category_group: "",
  category: "",
  tag_names: [],
};

const ProductOptionGroup: React.FC<{ optionGroup: OptionGroup; disabled?: boolean }> = ({
  optionGroup,
  disabled,
}) => {
  const t = useTranslation();
  const isCustomResponsePatternGiven =
    R.isString(optionGroup.custom_response_pattern) &&
    !R.isEmpty(optionGroup.custom_response_pattern);
  const regex: RegExp | undefined = isCustomResponsePatternGiven
    ? new RegExp(optionGroup.custom_response_pattern || "", "g")
    : undefined;
  return (
    <ProductOptionInputLabel>
      {t(optionGroup.name)}
      {optionGroup.is_custom_response ? (
        <input name={optionGroup.id} required pattern={regex?.source} disabled={disabled}></input>
      ) : (
        <select name={optionGroup.id} required disabled={disabled}>
          {optionGroup.options.map((option) => (
            <option key={option.id} value={option.id} disabled={option.leftover_stock <= 0}>
              {t(option.name)}
              {option.additional_price > 0 && (
                <>
                  {" "}
                  [ +<PriceDisplay price={option.additional_price} /> ]{" "}
                </>
              )}
              {option.leftover_stock <= 0 && <> ({t("품절")}) </>}
            </option>
          ))}
        </select>
      )}
    </ProductOptionInputLabel>
  );
};

export const ProductInfoDialog: React.FC<DialogPropType> = ({
  open,
  header,
  product,
  optionFormRef,
  donationInputRef,
  actionButtons,
  disabled,
}) => {
  const t = useTranslation();
  const options = product.option_groups.map((group) => (
    <ProductOptionGroup key={group.id} optionGroup={group} disabled={disabled} />
  ));
  const actionButtonElements = actionButtons.map((button, index) => (
    <button key={index} onClick={button.onClick} style={button.style} disabled={disabled}>
      {button.labal}
    </button>
  ));
  const refundEndsAt = new Date(product.refundable_ends_at).toLocaleString();
  return (
    <ProductInfoDialogContainer open={open}>
      <ProductInfoDialogBox>
        <h3>{header}</h3>
        <div>
          <h4>
            {t(product.name)}{" "}
            <sup>
              [<PriceDisplay price={product.price} />]
            </sup>
          </h4>
          <MarkdownContainer>
            <Markdown>{product.description}</Markdown>
          </MarkdownContainer>
          <sub>
            {refundEndsAt}
            {t("까지 환불이 가능합니다.")}
          </sub>
          <hr />
          <fieldset>
            <legend>
              <h6>{t("옵션")}</h6>
            </legend>
            <form ref={optionFormRef}>{options}</form>
          </fieldset>
          {product.donation_allowed && (
            <>
              <hr />
              <fieldset>
                <legend>
                  <h6>{t("후원")}</h6>
                  <p style={{ fontSize: "0.8em" }}>
                    {t("후원을 통해 PyCon 한국 준비 위원회와 함께해주셔서 정말 감사합니다!")}
                    <br />
                    {t(
                      "혹시 추가로 후원하고 싶은 금액이 있으시면, 아래에 입력해주시면 추가로 후원해주실 수 있습니다!"
                    )}
                    <br />({t("금액은 ")}
                    <PriceDisplay price={product.donation_min_price} />
                    {t("에서 ")}
                    <PriceDisplay price={product.donation_max_price} />
                    {t(" 내로 추가 후원하실 수 있습니다.")})
                  </p>
                </legend>
                <ProductOptionInputLabel>
                  {t("후원 금액")}
                  <input
                    ref={donationInputRef}
                    type="number"
                    name="donation"
                    defaultValue={product.donation_min_price}
                    min={product.donation_min_price}
                    max={product.donation_max_price}
                    required
                    disabled={disabled}
                  />
                </ProductOptionInputLabel>
              </fieldset>
            </>
          )}
          <TicketTOSPolicy />
        </div>
        <div>{actionButtonElements}</div>
      </ProductInfoDialogBox>
    </ProductInfoDialogContainer>
  );
};

const ProductInfoDialogContainer = styled.dialog`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.75);

  a {
    text-decoration: none;
    color: #febd99;
  }
`;
const ProductInfoDialogBox = styled.div`
  max-height: 80%;
  max-width: 80%;

  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  padding: 1rem;

  font-weight: bold;

  overflow-y: auto;

  button {
    padding: 0.3rem 1rem;
    margin: 0.5rem 0.5rem 0 0.5rem;
  }
`;

const ProductOptionInputLabel = styled.label`
  display: block;

  font-weight: bold;
  font-size: 0.75em;

  input,
  select {
    height: fit-content;
    padding: 0.25rem 0.5rem;
    margin: 0.5rem 0;
  }
`;

const MarkdownContainer = styled.div`
  p {
    margin: 0;
    font-size: 0.8em;
  }

  ul {
    margin-left: 1rem;
  }
`;

const ProductTOSPolicyContainer = styled.div`
  font-size: 0.8em;
`;
