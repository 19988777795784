import { PaymentRequest, getCSRFToken } from "api_v2"
import { EmailSignInRequest, EmailSignInSuccessResponse, SocialSessionStatusType, SocialSignInRequest } from "models/api/user"

export const redirectToProvider = async (socialSignInInfo: SocialSignInRequest) => {
  const f = document.createElement('form')
  f.method = 'POST'
  f.action = `${process.env.REACT_APP_PYCONKR_API}/authn/social/browser/v1/auth/provider/redirect`

  Object.entries({...socialSignInInfo, csrfmiddlewaretoken: getCSRFToken() ?? ''}).forEach(
    ([key, value]) => {
      const d = document.createElement('input')
      d.type = 'hidden'
      d.name = key
      d.value = value
      f.appendChild(d)
    }
  )
  document.body.appendChild(f)
  f.submit()
  document.body.removeChild(f)
}

export const signInWithEmail = async (data: EmailSignInRequest) => {
  const response = await PaymentRequest({
    route: 'authn/social/browser/v1/auth/login',
    method: 'POST',
    data,
    requiresAuth: false,
  })
  return (await response.json()) as EmailSignInSuccessResponse
}

export const getUserInfo = async () => {
  const response = await PaymentRequest({
    route: 'authn/social/browser/v1/auth/session',
    method: 'GET',
  })
  return (await response.json()) as SocialSessionStatusType
}

export const logoutSession = () => PaymentRequest({
  route: 'authn/social/browser/v1/auth/session',
  method: 'DELETE',
  ignoreAuthError: true,
})
